const socialIcon = (props) =>  (
    <a 
        className="nav-link"
        href={props.link}
        target='_blank'
        rel="noreferrer"
    >
        {props.icon}
    </a>
);

export default socialIcon;