import logo from './logo.svg';
import Message from './message';
import SocialNav from './socialNav';

const Main = (props) =>  (
  <div className="App">
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <Message text="Hello World!" />
      <SocialNav />
    </header>
  </div>
);

export default Main;