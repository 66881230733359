import SocialIcon from './socialIcon';
import { SiGithub, SiLinkedin, SiStackoverflow, SiYoutube, SiMedium, SiGoogleplay } from "react-icons/si";
import { IoCubeOutline } from "react-icons/io5";

const socials = [
    {
        icon: <SiGithub />,
        link: "https://github.com/Lifka",
    },
    {
        icon: <SiLinkedin />,
        link: "https://www.linkedin.com/in/javier-izquierdo-vera/",
    },
    {
        icon: <SiMedium />,
        link: "https://javierizquierdovera.medium.com/",
    },
    {
        icon: <SiGoogleplay />,
        link: "https://play.google.com/store/apps/dev?id=6124861953107952097",
    },
    {
        icon: <SiStackoverflow />,
        link: "https://stackexchange.com/users/5815004/lifka?tab=accounts",
    },
    {
        icon: <IoCubeOutline />,
        link: "https://www.hackthebox.eu/profile/179164",
    },
    {
        icon: <SiYoutube />,
        link: "https://www.youtube.com/channel/UCf-98d8W4mliB_ehzyPwQkQ?view_as=subscriber",
    },
];

const SocialNav = (props) =>  {
    const displaySocial = socials.map(social => 
        <li className="nav-item" key={social.link}>
            <SocialIcon icon={social.icon} link={social.link}/>
        </li>
    );
    return (
        <nav className="navbar navbar-expand">
            <ul className="navbar-nav">
                {displaySocial}
            </ul>
        </nav>
    );
};

export default SocialNav;