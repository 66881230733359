import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Main from './main';

const App = () => {
  return (
    <Switch>
        <Route exact path="/" component={Main} /> 
    </Switch>
  );
};

export default App;
